import { create } from "zustand";
import { persist } from "zustand/middleware";

import type { IEnvironmentState } from "./persistedSlices/environment";
import createEnvironmentSlice from "./persistedSlices/environment";
import type { ISelectionState } from "./persistedSlices/selection";
import createSelectionSlice from "./persistedSlices/selection";
import createSnackbarSlice, {
  ISnackbarState,
} from "./persistedSlices/snackbar";
import type { IWishlistState } from "./persistedSlices/wishlist";
import createWishlistSlice from "./persistedSlices/wishlist";

export type IState = ISelectionState &
  IEnvironmentState &
  IWishlistState &
  ISnackbarState;

const usePersistedStore = create<IState>()(
  persist(
    (...a) => ({
      ...createSelectionSlice(...a),
      ...createEnvironmentSlice(...a),
      ...createWishlistSlice(...a),
      ...createSnackbarSlice(...a),
    }),
    {
      name: "persisted-state",
    }
  )
);

export default usePersistedStore;
