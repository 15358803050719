"use client";
import type { StateCreator } from "zustand";

import type { IState } from "../persistedStore";

export interface ISnackbarState {
  snackbar: {
    text: string;
  };
  addSnackbar: (snackbar: ISnackbarState["snackbar"]) => void;
}

export const createSnackbarSlice: StateCreator<
  IState,
  [],
  [],
  ISnackbarState
> = (set, get) => ({
  snackbar: {
    text: "",
  },
  addSnackbar: (snackbar) => {
    set({ snackbar });
  },
});

export default createSnackbarSlice;
